import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                { name: "Henry Bourgot", post: "Invoza User", desc: "Dépannage rapide et efficace " },
                { name: "Jerome leroix", post: "Invoza User", desc: "Ils sont venu et 12 minutes ." },
                { name: "Céline michelov", post: "Invoza User", desc: "Je recommande vivement Personnel agréable très à l écoute super arrangeant encore merci a vous" },
                { name: "Ibrahim lanista", post: "Invoza User", desc: "Équipe hyper avenante et professionnelleNous avons pu compter sur eux pour l’ouverture de notre véhicule dont La centralisation s’est déclenchée avec les clefs dedans. Avec une infini patience et bcp de douceur" },
                { name: "Rodney Tyler", post: "Invoza User", desc: "Très bon service, très professionnel. Rapport qualité prix excellent.Je vous le recommande vivement." },
            ],
            clients2: [
                { image: clinet1 },
                { image: clinet3 },
                { image: clinet4 },
                { image: clinet6 },
            ],
            responsive: {
                576: {
                    items: 2
                },
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="clients">
                    <Container>
                      

                        <Row>
                            <Col lg={12}>
                                
                                <OwlCarousel
                                    className="owl-theme testi-carousel"
                                    id="testi-carousel"
                                    items={1}
                                    loop={true}
                                    margin={10}
                                    nav={true}
                                    responsive={this.state.responsive}
                                >
                                    <ClientBox clients={this.state.clients} />
                                </OwlCarousel>
                            </Col>
                        </Row>


                        <Row className="mt-5">
                            {
                                this.state.clients2.map((client, key) =>
                                    <Col xl={3} sm={6} key={key} >
                                        <div className="client-images">
                                            <img src={client.image} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>

                    </Container>

                </section>

                <section className="section bg-primary">
                    <Container>
                        <Row className="justify-content-center mb-5">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Nos statistique de 2022</h4>
                                    <p>La meilleures solutions pour vous!</p>
                                </div>
                            </Col>
                        </Row>

                        <Counter />
                    </Container>

                </section>
            </React.Fragment>
        );
    }
}

export default Clients;