import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : "activity", title : "Rapidité", desc : "On vous envoie un technicien local très rapidement." },
                { icon : "headphones", title : "Un soutien impressionnant", desc : "Une gestion ultra rapide de votre pane  par téléphone." },
                { icon : "wind", title : "Démarrage", desc : "booster batterie." },
                { icon : "alert-octagon", title : "Diagnostique", desc : "effacement de voyant sur place." },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section bg-light" id="services">
            <Container>
            <SectionTitle
                subtitle="Services"
                title="Services que nous fournissons"
                desc="mèche, booster batterie, effacement de voyant, bricolage rapide."
            />

                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>


                <Row className="mt-4">
                    <Col lg={12}>
                        <div className="text-center">
                            <Link to="#" className="btn btn-success">06 25 29 26 25</Link>
                        </div>
                    </Col>
                </Row>

            </Container>

        </section>
            </React.Fragment>
        );
    }
}

export default Process;