import React, { Component } from 'react';
import { Row } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

class Counter extends Component {
    state = {
        counters: [
            { icon: "phone-call", title: "Appel reçu", start: 700, end: 9042 },
            { icon: "check", title: "Remorquage", start: 10, end: 2581 },
            { icon: "check", title: "Depannages", start: 2, end: 800 },
            { icon: "heart", title: "satisfaction", start: 25, end: 2500 },

        ],
    }
    render() {
        return (
            <React.Fragment>
                <Row id="counter">
                    <CounterBox counters={this.state.counters} />
                </Row>
            </React.Fragment>
        );
    }
}

export default Counter;