
import Index4 from "./pages/Index4/Index4";


const routes = [
  { path: "/", component: Index4 },

];

export default routes;
