import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/features/ima-3.jpg";
import feature2 from "../../assets/images/features/ima-4.jpg";


class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Dépannage Auto" },
                { title : "Dépannage Moto" },
            ],
            smallFeatures2 : [
                { title : "Remorquage Moto" },
                { title : "Remorquage Auto" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section" id="features">
            <Container>
            <SectionTitle
                subtitle="À propos de nous"
                title="Nous pouvons intervenir sur place pour divers types de pannes auto."
                desc="Nos prix sont acceptés par les assurances, et vous pouvez les soumettre à votre assurance pour être remboursé."
            />

                <Row>
                    <Col lg={5}>
                        <div>
                            <FeatureBox icon="bar-chart-2" title="DEPANNAGE AUTO" smallFeatures={this.state.smallFeatures1} desc="Nous intervenons rapidement à l’endroit de votre choix. Nos techniciens ont une grande expérience du dépannage hors atelier, à domicile ou sur la route, à l’aide d’un matériel moderne, de jour comme de nuit." link="#" />
                        </div>
                    </Col>

                    <Col lg={5} sm={8} className="ml-lg-auto">
                        <Card className="border border-light shadow-none mt-5 mt-lg-0">
                            <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature1} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Row className="mt-5 pt-5">
                    <Col lg={5} sm={8}>
                        <Card className="border border-light shadow-none">
                            <CardHeader className=" border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature2} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5} className="ml-lg-auto">
                        <div className="mt-4 mt-lg-0">
                        <FeatureBox icon="pie-chart" title="Remorquage Auto" smallFeatures={this.state.smallFeatures2} desc="Nous répondons aux demandes de remorquage en toutes circonstances, mauvaises conditions météorologiques, neige, inondations, circulation dense ou dans les lieux difficiles d’accès." link="#" />
                        </div>
                    </Col>

                </Row>

            </Container>

        </section>
            </React.Fragment>
        );
    }
}

export default Features;