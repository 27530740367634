import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import bg1 from "../../assets/images/bg-1.png";
import homeImg from "../../assets/images/home-img.png";

class Section extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="hero-section" id="home" style={{
                    backgroundColor: 'black',  // Change background to black
                    backgroundImage: `url(${bg1})`,
                    backgroundPosition: 'center center',
                }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                    <p className="font-16 text-uppercase" style={{ color: 'white' }}>Allo remorquage</p>  
                                    <h1 className="hero-title mb-4" style={{ color: 'white' }}>Disposition 7j/7 pour toute demande ou question. N'hésitez pas à nous contacter ! <span className="text-primary">AUTO DEPANNEUR</span></h1>

                                    <p style={{ color: "white" }}>
                                        Devis gratuit par téléphone de dépannage et du remorquage auto & moto  prix fixe</p>

                                    <div className="mt-4">
                                        <Link to="#" className="btn btn-primary mt-2 mr-2">06 25 29 26 25</Link>

                                    </div>
                                </div>

                            </Col>

                            <Col lg={6} sm={8}>
                                <div className="mt-5 mt-lg-0">
                                    <img src={homeImg} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;